<template>
  <v-card>
    <v-card-title>Información Personal</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :items="generos"
              v-model.number="genero"
              item-text="nombre"
              item-value="id"
              label="Género"
              value="1"
              required
              :rules="selectRules"
            ></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="nombres"
              :counter="100"
              label="Nombres"
              required
              :rules="nombresRules"
              @input="nombres = nombres.toUpperCase()"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              v-model="apellidos"
              :counter="100"
              label="Apellidos"
              required
              :rules="nombresRules"
              @input="apellidos = apellidos.toUpperCase()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuNacimiento"
              v-model="menuNacimiento"
              :close-on-content-click="false"
              :return-value.sync="nacimiento"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="nacimiento"
                  label="Nacimiento"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="nacimiento"
                no-title
                scrollable
                locale="es"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuNacimiento = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuNacimiento.save(nacimiento)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="documentos"
              v-model.number="documentotipo"
              item-text="nombre"
              item-value="id"
              label="Tipo Documento"
              value="1"
              required
              :rules="selectRules"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="documentonumero"
              label="Documento Nº"
              :counter="11"
              :rules="documentoRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="legajo"
              label="Legajo"
              :counter="50"
              :rules="legajoRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="email"
              label="Email"
              :counter="254"
              :rules="emailRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="empleado">
          <v-col cols="12" md="3">
            <v-switch
              v-model="estado"
              :label="estado ? 'Estado: Activo' : 'Estado: Inactivo'"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" @click="addTelefonoForm">
              + Agregar Teléfono</v-btn
            >
          </v-col>
        </v-row>
        <TelefonoInputs
          v-for="(telefono, index) in telefonos"
          :key="index"
          :telefono="telefono"
          @telform-deleted="removeTelefonoForm"
        >
          {{ index }}
        </TelefonoInputs>
        <br />
        <v-divider> </v-divider>
      </v-container>
      <v-card-title>Datos Complementarios</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              :items="sectores"
              item-text="nombre"
              item-value="id"
              label="Sector"
              v-model="sector"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn
            v-if="!empleado"
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSaveEnrol"
            @click="onSubmit(true)"
            ><v-icon left dark> mdi-check </v-icon>
            <v-icon left dark> mdi-camera </v-icon>Guardar y Enrolar</v-btn
          >
          <v-btn color="error" to="/empleados"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import EmpleadosService from "../services/EmpleadosService";
import TelefonoInputs from "./TelefonoInputs.vue";

export default {
  name: "EmpleadoForm",
  components: {
    TelefonoInputs,
  },

  data() {
    return {
      tipos: [],
      documentos: [],
      sectores: [],
      formValidity: false,
      generos: null,
      genero: null,
      nombres: "",
      apellidos: "",
      documentotipo: null,
      telefonos: [],
      nacimiento: "",
      menuNacimiento: false,
      documentonumero: "",
      estado: false,
      legajo: "",
      email: "",
      sector: null,
      error: false,
      loadingSave: false,
      loadingSaveEnrol: false,
      nombresRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      documentoRules: [
        (v) => !!v || "Debe ingresar un número de documento",
        (v) =>
          (v && v.length <= 11) ||
          "El documento no puede tener mas de 11 caracteres.",
        (v) =>
          (v && v.length > 6) ||
          "El documento no puede tener menos de 7 caracteres.",
        (v) => (v && !isNaN(v)) || "Debe ingresar un número.",
      ],
      legajoRules: [
        (v) =>
          v.length <= 50 || "El legajo no puede tener mas de 50 caracteres.",
        (v) => !isNaN(v) || "Debe ingresar un número.",
      ],
      emailRules: [
        (v) => /.+@.+\..|$/.test(v) || "La dirección de Email no es válida.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
    };
  },
  props: {
    empleado: {
      type: Object,
      required: false,
    },
  },
  created() {
    EmpleadosService.getTiposDocumento()
      .then((response) => {
        this.documentos = response.data;
      })
      .catch((error) => console.log(error));
    EmpleadosService.getGeneros()
      .then((response) => {
        this.generos = response.data;
      })
      .catch((error) => console.log(error));
    EmpleadosService.getSectores()
      .then((response) => {
        this.sectores = response.data.results;
        this.sectores.push({ id: null, nombre: "Sin Asignar" });
      })
      .catch((error) => console.log(error));

    if (this.empleado) {
      this.genero = this.empleado.genero;
      this.nombres = this.empleado.nombres;
      this.apellidos = this.empleado.apellidos;
      this.documentotipo = this.empleado.documento_tipo;
      this.telefonos = this.empleado.telefonos;
      this.nacimiento = this.empleado.fecha_nacimiento;
      this.documentonumero = this.empleado.documento_nro;
      this.legajo = this.empleado.legajo;
      this.email = this.empleado.email;
      if (this.empleado.sector) this.sector = this.empleado.sector;
      this.estado = this.empleado.estado;
    }
  },

  methods: {
    onSubmit(enrol = false) {
      if (enrol) this.loadingSaveEnrol = true;
      else this.loadingSave = true;

      let empleadoInstance = {
        nombres: this.nombres,
        apellidos: this.apellidos,
        documento_tipo: this.documentotipo,
        documento_nro: this.documentonumero,
        genero: this.genero,
        fecha_nacimiento: this.nacimiento,
        legajo: this.legajo,
        email: this.email,
        telefonos: this.telefonos,
        sector: this.sector,
      };
      if (this.empleado) empleadoInstance.estado = this.estado;

      if (!this.empleado) this.empleadoCrear(empleadoInstance, enrol);
      else this.empleadoActualizar(empleadoInstance);
    },

    empleadoActualizar(empleadoInstance) {
      empleadoInstance.id = this.empleado.id;
      EmpleadosService.putEmpleado(empleadoInstance)
        .then((response) => {
          this.$router.push({
            name: "EmpleadoDetalle",
            params: { id: empleadoInstance.id },
          });
        })
        .catch((error) => {
          this.error = error.response.data;
          this.loadingSave = false;
        });
    },

    empleadoCrear(empleadoInstance, enrol = false) {
      EmpleadosService.postEmpleado(empleadoInstance)
        .then((response) => {
          if (enrol) {
            let id = response.data.id;
            this.$router.push({
              name: "EmpleadoEnrolar",
              params: { id: id, nuevo: "nuevo" },
            });
          } else {
            this.$router.push({
              name: "Empleados",
            });
          }
        })
        .catch((error) => {
          this.error = error;
          if (enrol) this.loadingSaveEnrol = false;
          else this.loadingSave = false;
        });
    },

    validate() {
      this.$refs.form.validate();
    },
    addTelefonoForm() {
      this.telefonos.push(this.createTelefono());
    },
    removeTelefonoForm(telefono) {
      if (this.telefonos.length > 0)
        this.telefonos.splice(this.telefonos.indexOf(telefono), 1);
    },
    createTelefono() {
      telefono = null;
      let telefono = {
        id: null,
        persona_id: null,
        numero: "",
        tipo: null,
      };
      return telefono;
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
