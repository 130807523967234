<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-select
          :items="tipos"
          v-model="telefono.tipo"
          item-text="nombre"
          item-value="id"
          label="Tipo"
          value="1"
        >
          :rules="selectRules" required ></v-select
        >
      </v-col>
      <v-col md="4">
        <v-text-field
          prepend-icon="mdi-phone"
          v-model="telefono.numero"
          label="Número"
          append-outer-icon="mdi-close"
          :counter="30"
          :rules="telefonoRules"
          required
          @click:append-outer="onDelete"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "TelefonoInputs",
  data() {
    return {
      tipos: [],
      // search: "",
      // telefonotipo: "",
      // telefononumero: "",
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      telefonoRules: [
        (v) => !!v || "Debe ingresar un número de teléfono",
        (v) =>
          v.length <= 30 ||
          "La longuitud del campo número de teléfono debe ser menor o igual a 30 caracteres.",
      ],
    };
  },
  props: {
    telefono: {
      type: Object,
      required: true,
    },
    // key: {
    //   type: Number,
    //   required: true,
    // }
  },
  methods: {
    onDelete() {
      this.$emit("telform-deleted", this.telefono);
    },
  },
  created() {
    EmpleadosService.getTiposTelefono()
      .then((response) => {
        this.tipos = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
