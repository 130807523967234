<template>
  <v-card>
    <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
      <v-container>
        <v-alert dense v-if="error" type="error"
          >Ocurrió un error al enviar la solicitud. Compruebe los datos
          ingresados</v-alert
        >
        <v-row>
          <v-col>
            <v-text-field
              v-model="nombre"
              :counter="100"
              label="Nombre del Lugar"
              required
              :rules="nombreRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="descripcion"
              :counter="300"
              label="Descripción"
              rows="1"
              auto-grow
              :rules="descripcionRules"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="latitud"
              :counter="100"
              label="Latitud"
              float
              required
              @change="onLatitudChange($event)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="longitud"
              :counter="100"
              label="Longitud"
              number
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <br />
        <v-divider> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!formValidity" color="primary" type="submit"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/lugares"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import EmpleadosService from "../services/EmpleadosService";

export default {
  name: "LugarForm",
  components: {},

  data() {
    return {
      nombre: "",
      descripcion: "",
      latitud: 0,
      longitud: 0,
      formValidity: false,
      error: false,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      descripcionRules: [
        (v) =>
          v.length <= 300 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
    };
  },
  props: {
    lugar: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.lugar) {
      this.nombre = this.lugar.nombre;
      this.descripcion = this.lugar.descripcion;
      this.latitud = this.lugar.latitud;
      this.longitud = this.lugar.longitud;
    }
  },

  methods: {
    onSubmit() {
      let lugarInstance = {
        nombre: this.nombre,
        descripcion: this.descripcion,
        latitud: this.latitud,
        longitud: this.longitud,
      };
      if (!this.lugar) this.lugarNuevo(lugarInstance);
      else this.lugarActualizar(lugarInstance);
    },

    lugarActualizar(lugarInstance) {
      lugarInstance.id = this.lugar.id;
      EmpleadosService.putLugar(lugarInstance)
        .then((response) => {
          this.$router.push({
            name: "LugarDetalle",
            params: { id: lugarInstance.id },
          });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    lugarNuevo(lugarInstance) {
      EmpleadosService.postLugar(lugarInstance)
        .then((response) => {
          this.$router.push({
            name: "Lugares",
          });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    validate() {
      this.$refs.form.validate();
    },

    onLatitudChange(texto) {
      console.log("Texto: " + texto);
      let coords = texto.split(", ");
      if (coords.length != 2) return;
      if (isNaN(coords[0]) || isNaN(coords[1])) return;
      this.latitud = coords[0];
      this.longitud = coords[1];
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
