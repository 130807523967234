<template>
  <v-card>
    <v-card-title>Datos Generales</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="nombre"
              :counter="100"
              label="Nombre"
              required
              :rules="nombreRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field
              v-model="descripcion"
              :counter="300"
              label="Descripción"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="tipos"
              v-model="tipo"
              item-text="nombre"
              item-value="id"
              label="Tipo"
              value="1"
              required
              :rules="selectRules"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="tipo == '2'">
          <v-col cols="12" md="5">
            <EmpleadoAutocomplete
              ref="empleadoAutocomplete"
              :empleadoGuardado="empleado"
            ></EmpleadoAutocomplete>
          </v-col>
        </v-row>
        <br />
        <v-divider></v-divider>
      </v-container>
      <v-card-title>Configuración del dispositivo</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-switch
              v-model="guardarGeo"
              :label="
                guardarGeo
                  ? 'Guardar Geolocalización: Sí'
                  : 'Guardar Geolocalización: No'
              "
            ></v-switch>
          </v-col>
          <v-col cols="12" md="4">
            <div class="text--secondary">Tolerancia Geolocalización</div>
            <v-slider
              v-model="toleranciaGeo"
              class="align-center"
              :label="toleranciaGeo + ' Km.'"
              max="1"
              min="0"
              step="0.01"
              inverse-label
              hide-details
            >
            </v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-switch
              v-model="guardarFoto"
              :label="guardarFoto ? 'Guardar Foto: Sí' : 'Guardar Foto: No'"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="4">
            <v-switch
              v-model="reconocimientoFacial"
              :label="
                reconocimientoFacial
                  ? 'Reconocimiento Facial: Sí'
                  : 'Reconocimiento Facial: No'
              "
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="dispositivo">
          <v-col cols="12" md="4">
            <v-switch
              v-model="estado"
              :label="estado ? 'Estado: Activo' : 'Estado: Inactivo'"
            ></v-switch>
          </v-col>
        </v-row>
        <v-divider> </v-divider>
      </v-container>
      <div v-if="!dispositivo">
        <v-card-title>Datos de Acceso</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="username"
                :counter="50"
                label="Nombre de Login"
                required
                prepend-icon="mdi-account-circle"
                :rules="usernameRules"
                :suffix="'@' + tenant"
                @input="username = username.toLowerCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="password"
                :counter="50"
                :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Contraseña"
                required
                :rules="passwordRules"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="confirmPassword"
                :counter="50"
                :type="showConfirmPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Confirmar Contraseña"
                required
                :rules="confirmPasswordRules"
                @click:append="showConfirmPassword = !showConfirmPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider> </v-divider>
        </v-container>
      </div>
      <v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loading"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/dispositivos"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import EmpleadosService from "../services/EmpleadosService";
import EmpleadoAutocomplete from "../components/autocompletes/EmpleadoAutocomplete.vue";
const zxcvbn = require("zxcvbn");

export default {
  name: "DispositivoForm",
  components: { EmpleadoAutocomplete },

  data() {
    return {
      tipos: [
        { id: "1", nombre: "General" },
        { id: "2", nombre: "Personal" },
      ],
      tipo: "1",
      formValidity: false,
      nombre: "",
      descripcion: "",
      empleado: null,
      guardarGeo: true,
      toleranciaGeo: 0.5,
      guardarFoto: true,
      reconocimientoFacial: true,
      estado: true,
      username: "",
      password: "",
      confirmPassword: "",
      tenant: "",
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
      error: null,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      usernameRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length > 4 || "El nombre debe tener al menos 5 caracteres de largo",
        (v) =>
          (v.match(/^[a-z0-9]*$/) && true) ||
          "El nombre solo debe incluir letras o números",
      ],
      passwordRules: [
        (v) =>
          zxcvbn(v).score >= 3 ||
          "La contraseña no es segura. Intente mezclar letras, números y símbolos.",
        (v) =>
          !v.includes(this.username) ||
          "La contraseña no puede incluir el nombre de usuario",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      confirmPasswordRules: [
        (v) => v == this.password || "Las contraseñas deben coincidir",
      ],
    };
  },

  props: {
    dispositivo: {
      type: Object,
      required: false,
    },
  },
  created() {
    const loggedIn = localStorage.getItem("user");
    this.tenant = JSON.parse(loggedIn).tenant;

    if (this.dispositivo) {
      this.nombre = this.dispositivo.nombre;
      this.descripcion = this.dispositivo.descripcion;
      this.tipo = this.dispositivo.tipo.toString();
      this.empleado = this.dispositivo.empleado;
      this.guardarGeo = this.dispositivo.guardar_geolocalizacion;
      this.toleranciaGeo = this.dispositivo.tolerancia_geolocalizacion;
      this.guardarFoto = this.dispositivo.guardar_foto;
      this.reconocimientoFacial = this.dispositivo.reconocimiento_facial;
      this.estado = this.dispositivo.estado;
    }
  },

  methods: {
    onSubmit(enrol = false) {
      this.loading = true;

      let dispositivoInstance = {
        nombre: this.nombre,
        descripcion: this.descripcion,
        tipo: this.tipo,
        empleado: this.empleado,
        guardar_geolocalizacion: this.guardarGeo,
        tolerancia_geolocalizacion: this.toleranciaGeo,
        guardar_foto: this.guardarFoto,
        reconocimiento_facial: this.reconocimientoFacial,
        estado: this.estado,
      };
      if (this.tipo == "2")
        dispositivoInstance.empleado =
          this.$refs.empleadoAutocomplete.selEmpleado;
      if (this.dispositivo) {
        console.log("True dispo");
        dispositivoInstance.estado = this.estado;
        this.dispositivoActualizar(dispositivoInstance);
      } else {
        console.log("Falso dispo");
        let dispositivoUserInstance = {
          username: this.username,
          password: this.password,
          // confirm_password: this.confirmPassword,
        };
        this.dispositivoCrear(dispositivoInstance, dispositivoUserInstance);
      }
    },

    dispositivoActualizar(dispositivoInstance) {
      dispositivoInstance.id = this.dispositivo.id;
      EmpleadosService.putDispositivo(this.dispositivo.id, dispositivoInstance)
        .then((response) => {
          this.$router.push({
            name: "DispositivoDetalle",
            params: { id: dispositivoInstance.id },
          });
        })
        .catch((error) => {
          this.error = error.response.data;
          this.loading = false;
        });
    },

    dispositivoCrear(dispositivoInstance, dispositivoUserInstance) {
      dispositivoInstance.user = dispositivoUserInstance;
      EmpleadosService.postDispositivo(dispositivoInstance)
        .then((response) => {
          this.$router.push({
            name: "Dispositivos",
          });
        })
        .catch((error) => {
          this.error = error.response.data;
          this.loading = false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
